import { logger } from "../Logger";

export class LocalizationService {
    // Mapping of Teams language codes to Teamcenter Language codes
    private static localeMapping: { [key: string]: string } = {
        "en-us": "en_US",
        "cs-cz": "cs_CZ",
        "de-de": "de_DE",
        "es-es": "es_ES",
        "fr-fr": "fr_FR",
        "it-it": "it_IT",
        "ja-jp": "ja_JP",
        "ko-kr": "ko_KR",
        "pl-pl": "pl_PL",
        "pt-br": "pt_BR",
        "ru-ru": "ru_RU",
        "zh-cn": "zh_CN",
        "zh-tw": "zh_TW"
    };

    /**
     * This method uses a predefined mapping to translate language codes from Teams to Teamcenter's language format
     * If the provided Teams locale does not have a corresponding mapping, it defaults to 'en_US'.
     * @param teamsLang - The language set in teams.
     * @returns The corresponding Teamcenter language code.
     */
    public static getTeamcenterLanguage(teamsLang: string): string {
        const teamcenterLang = this.localeMapping[teamsLang];
        return teamcenterLang ? teamcenterLang : "en_US";
    }

    /**
     * This method retrieves localization messages from a JSON file, parse its contents and return the resulting object.
     * @returns A promise that resolves to an object containing key-value pairs of localized messages.
     */
    public static async getMessages(teamcenterLang: string): Promise<{ [key: string]: string }> {
        const filePath = `/lang/TeamsTcAppMessages_${teamcenterLang}.json`;
        const response = await fetch(filePath);
        if (!response.ok) {
            logger.logError(`Failed to fetch localization file: ${response.statusText}`);
            return {};
        }
        const text = await response.text();
        return JSON.parse(text);
    }
}
