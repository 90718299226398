import React from 'react';
import { TeamsFxContext } from '../../Context';
import { DatePicker, TimePicker, IComboBox ,ITimeRange, initializeIcons, TimePickerValidationResultData, IDatePickerStrings, ITimePickerStrings } from "@fluentui/react";

interface DateTimeProps {
  currentDate: Date | null;
  onDateChange: (date: Date, id:string) => void;
  errorExists: (isError: boolean) => void;
  id: string;
  isTimeEnabled: boolean | undefined;
}

const DateTime: React.FC<DateTimeProps> = ({ currentDate, onDateChange, errorExists, id, isTimeEnabled }) => {
  const teamsContext = React.useContext(TeamsFxContext);
  const i18n = teamsContext.i18n;

  const datePickerStrings: IDatePickerStrings = {
    days: [i18n.Sunday, i18n.Monday, i18n.Tuesday, i18n.Wednesday, i18n.Thursday, i18n.Friday, i18n.Saturday],
    shortDays: [i18n.SundayShort, i18n.MondayShort, i18n.TuesdayShort, i18n.WednesdayShort, i18n.ThursdayShort, i18n.FridayShort, i18n.SaturdayShort],
    months: [i18n.January, i18n.February, i18n.March, i18n.April, i18n.May, i18n.June, i18n.July, i18n.August, i18n.September, i18n.October, i18n.November, i18n.December],
    shortMonths: [i18n.JanuaryShort, i18n.FebruaryShort, i18n.MarchShort, i18n.AprilShort, i18n.MayShort, i18n.JuneShort, i18n.JulyShort, i18n.AugustShort, i18n.SeptemberShort, i18n.OctoberShort, i18n.NovemberShort, i18n.DecemberShort],
    goToToday: i18n.GoToToday,
    prevMonthAriaLabel: i18n.PrevMonthAriaLabel,
    nextMonthAriaLabel: i18n.NextMonthAriaLabel,
    prevYearAriaLabel: i18n.PrevYearAriaLabel,
    nextYearAriaLabel: i18n.NextYearAriaLabel,
    prevYearRangeAriaLabel: i18n.PrevYearRangeAriaLabel,
    nextYearRangeAriaLabel: i18n.NextYearRangeAriaLabel,
    closeButtonAriaLabel: i18n.CloseText,
    monthPickerHeaderAriaLabel: i18n.MonthPickerHeaderAriaLabel,
    yearPickerHeaderAriaLabel: i18n.YearPickerHeaderAriaLabel,
    isRequiredErrorMessage: i18n.IsRequiredErrorMessage
  };

  const timePickerStrings: ITimePickerStrings = {
    invalidInputErrorMessage: i18n.InvalidInputErrorMessage

  };
  const isTimePickerErrorExists = React.useRef(false);
  const [selectedDate, setSelectedDate] = React.useState< Date | null | undefined>( null);
  const [selectedTime, setSelectedTime] = React.useState<Date | null>(null);
  const [loaded, setLoaded] = React.useState<boolean>(false);

  const timeRange: ITimeRange = {
    start: 0,
    end: 24,
  };

  React.useEffect(() => {
    if(currentDate){
      setSelectedDate(currentDate);
      setSelectedTime(currentDate);
    }
    else{
      //set default time as 00:00
      const newDate = new Date();
      newDate.setHours(0, 0, 0, 0);
      setSelectedTime(newDate);
    }
    initializeIcons(); // Method to load icons
    setLoaded(true); // Set loaded to true after setting the date and time
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFormatDate = (date?: Date) => {
    return !date
      ? ""
      : `${datePickerStrings.months[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
  };

  const handleDateChange = (data: Date | null | undefined) => {
    if(isValidDate(data)){
      const newDate = data ? new Date(data) : new Date();
      if( selectedTime instanceof Date && !isNaN(selectedTime.getTime())) {
        const hours = selectedTime.getHours();
        const minutes = selectedTime.getMinutes();
        newDate.setHours(hours);
        newDate.setMinutes(minutes);
      }
      setSelectedDate(newDate);
      setSelectedTime(newDate);//keep selectedTime in sync with date
      onDateChange(newDate, id);
    }
  };

  const isValidDate = (date: Date| null | undefined): boolean => {
    return date instanceof Date && !isNaN(date.getTime());
  };

  const handleTimeChange = (_ev: any, newTime: Date) => {
    if(isValidDate(newTime)) {
      if(selectedDate === null || selectedDate === undefined) {
        setSelectedDate(newTime);//set the date to the selected time if date is not selected
      }
      setSelectedTime(newTime);
      onDateChange(newTime, id);
    }
  };

  const onValidationResult = (_ev: React.FormEvent<IComboBox>, timePickerValidationResultData: TimePickerValidationResultData) => {
    if (timePickerValidationResultData.errorMessage !== undefined) {
      const isErrorMessage = timePickerValidationResultData.errorMessage.length > 0;
      errorExists(isErrorMessage);
      isTimePickerErrorExists.current = isErrorMessage;
    }
  };

  const renderDateTimeWidget = () => {
    return (
      <>
        <div className={isTimeEnabled ? 'half-width' : 'full-width'}>
          <DatePicker
            placeholder={i18n.SelectDateText}
            value={selectedDate || undefined}
            onSelectDate={handleDateChange}
            strings={datePickerStrings}
            formatDate={onFormatDate}
          />
        </div>
        {isTimeEnabled && (
          <div className="time-picker-container">
            <TimePicker
              placeholder={i18n.SelectTimeText}
              strings={timePickerStrings}
              value={selectedTime || undefined}
              onChange={handleTimeChange}
              dateAnchor={selectedTime || undefined}
              timeRange={timeRange}
              onValidationResult={onValidationResult}
            />
          </div>
        )}
      </>
    );
  }

  return (
    <>
      <div className="date-time-container">
        { loaded && renderDateTimeWidget() }
      </div>
    </>
  );
};

export default DateTime;
